<template>
  <section>
    <v-container fluid>
      <v-row>
        <v-col md="12">
          <PageHeader
            :title="headercontent.title"
            :addlink="headercontent.addlink"
          />
        </v-col>
        <v-col md="12">
          <h3 class="filtertitle">Filter {{ headercontent.title }}</h3>
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
            class="form-section"
          >
            <v-row>
              <v-col cols="3">
                <v-text-field
                  v-model="title"
                  outlined
                  label="Job Titlte"
                  class="mr-btm-minus"
                ></v-text-field>
              </v-col>
             
              <v-col cols="12" class="submitbtn">
                <v-btn
                  :disabled="!valid"
                  class="submitbutton btn-dark"
                  @click="validate"
                >
                  Filter {{ headercontent.title }}
                </v-btn>
                <!-- <v-progress-circular
         
                  indeterminate
                ></v-progress-circular> -->
              </v-col>
            </v-row>
          </v-form>
        </v-col>
        <v-col md="12">
          <v-simple-table class="table">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    S/N
                  </th>
                  <th class="text-left">
                    Name
                  </th>
                  <th class="text-left">
                    Calories
                  </th>
                  <th class="text-right" colspan="2">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in desserts" :key="item.name">
                  <td>{{ item.sn }}</td>
                  <td>{{ item.name }}</td>
                  <td>{{ item.calories }}</td>
                  <td class="text-right">
                    <v-icon class="icons-action"> mdi-pencil </v-icon>
                    <v-icon class="icons-action"> mdi-delete </v-icon>
                    <v-icon class="icons-action"> mdi-download </v-icon>
                    
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <ul class="pagination-button">
            <li>Page : {{ (2 - 1) * 10 }} - 100</li>
            <li>
              <div>
                <v-btn @click="prevPage(0)" class="btn-dark">Prev</v-btn>

                <v-btn @click="nextPage(2)" class="btn-dark">Next</v-btn>
              </div>
            </li>
          </ul>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import PageHeader from "@/components/dashboard/PageHeader";
export default {
  name: "Page",
  components: { PageHeader },
  data: () => ({
    headercontent: {
      title: "Pages Listing",
      addlink: "/dashboard/page/add",
    },
    title: "",
    adsposition: "",
    valid: true,
    desserts: [
      {
        sn: 1,
        name: "Frozen Yogurt",
        calories: 159,
      },
      {
        sn: 2,
        name: "Ice cream sandwich",
        calories: 237,
      },
      {
        sn: 3,
        name: "Eclair",
        calories: 262,
      },
      {
        sn: 4,
        name: "Cupcake",
        calories: 305,
      },
    ],
  }),
  methods: {
    validate() {},
    nextPage(data) {
      console.log(data);
    },
    prevPage(data) {
      console.log(data);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
.form-section {
  padding: 15px 0;
  .col {
    padding: 0 10px;
  }
}
.filtertitle {
  margin-bottom: 10px;
}
.btn-dark {
  background: $tagcolor !important;
  color: $white;
  margin-top: -10px;
}

.table {
  box-shadow: 0px -1px 5px 2px rgba(0, 0, 0, 0.1);

  thead {
    border-bottom: 2px solid $menucolor;
    background: $tagcolor;
  }
  th {
    font-size: $normal_lfont !important;
    color: $white !important;
  }
}
.pagination-button {
  display: flex;
  list-style: none;
  padding-left: 0;
  justify-content: space-between;
  box-shadow: 2px 2px 5px 2px rgba(0, 0, 0, 0.1);
  .btn-dark {
    margin-top: 0;
    margin-left: 10px;
  }
}
.table-heading,
.pagination-button {
  border: 1px solid $menucolor;
  margin-bottom: 0;
  padding: 10px;
  align-items: center;
}
.icons-action{
  margin: 10px;
  font-size: $iconfont;
}
</style>
